import { Link } from "gatsby"
import React from "react"
import { Button } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BsClipboardData } from "react-icons/bs"
import { TiStopwatch } from "react-icons/ti"
import { FaCogs } from "react-icons/fa"

const hpe = require("../../images/hpe.png")
const intel = require("../../images/intel.png")
const dell = require("../../images/dell.png")
const amd = require("../../images/amd.png")

const IndustryPage = () => (
  <Layout>
    <SEO title="Industry Partners" description="" lang="en" meta={[]} />
    <div className="hero-wrapper secondary industry">
      <div className="text-block">
        <h1>Industry Partners</h1>

        <h3 className="text-center mb-4">
          Our partners within the tech industry.
        </h3>

        <Link to="/partners/join">
          <Button size="lg" className="hero-button primary">
            Become a Partner
          </Button>
        </Link>
      </div>
    </div>

    {/* Partner Listing */}
    <section className="partner-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 partner-block">
            <img src={intel} alt="" />
          </div>
          <div className="col-lg-3 partner-block">
            <img src={amd} alt="" />
          </div>
          <div className="col-lg-3 partner-block">
            <img src={hpe} alt="" />
          </div>
          <div className="col-lg-3 partner-block">
            <img src={dell} alt="" />
          </div>
        </div>
      </div>
    </section>

    {/* Flapmax Vision Section */}
    <section className="bg-darkened flapmax-about">
      <div className="container">
        <div className="d-flex justify-content-center">
          <h2 className="underline text-center mb-5">The Flapmax Advantage</h2>
        </div>

        <p className="text-center sub-specific">
          Leverage robust AI building blocks like image and video analytics,
          natural language processing, and IoT sensor analytics to:
        </p>
        <div className="row d-flex justify-content-around">
          <div className="col-lg-3">
            <BsClipboardData />
            <h3>Shorten AI Development Lifecycle</h3>
          </div>
          <div className="col-lg-3">
            <FaCogs />
            <h3>Integrate AI model with Cloud and IoT faster</h3>
          </div>
          <div className="col-lg-3">
            <TiStopwatch />
            <h3>Run AI models on high-performance hardware</h3>
          </div>
        </div>
        <p className="text-center sub-specific">
          Whether you are in the Media &amp; Entertainment industry, the
          Healthcare &amp; Life Sciences industry, or work with Industrial IoT,
          use our edge-to-cloud platform to increase data throughput, reduce
          processing latency, optimize energy efficiency, make application
          deployment more flexible, and launch solutions faster!
        </p>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Link to="/partners/join">
          <Button size="lg" className="hero-button primary">
            Become a Partner
          </Button>
        </Link>
      </div>
    </section>
  </Layout>
)

export default IndustryPage
